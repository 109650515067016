






























































































































import AddLocationModal from '@/components/locations/AddLocationModal.vue';
import AddEmployeeModal from '@/components/employees/AddEmployeeModal.vue';
import AddServiceModal from '@/components/services/AddServiceModal.vue';
import moment from 'moment';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCategoriesStore } from '@/store/categories-store';
import { Category } from '@/model/category';

export default Vue.extend({
  name: 'VendorResources',
  components: {
    AddServiceModal,
    AddEmployeeModal,
    AddLocationModal
  },
  props: {
    resources: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapStores(useCategoriesStore),
    categories(): Category[] {
      return this.categoriesStore.entities;
    },
    nextResourceToAdd(): {} | null {
      if (this.resources.service.name) {
        return null;
      }
      if (this.resources.employee.firstname) {
        return {
          label: this.$t('vendorSignUp.generalSettings.addService'),
          icon: 'fa-user',
          action: this.addOrEditService
        };
      }
      if (this.resources.location.name) {
        return {
          label: this.$t('vendorSignUp.generalSettings.addEmployee'),
          icon: 'fa-user',
          action: this.addOrEditEmployee
        };
      }
      return {
        label: this.$t('vendorSignUp.generalSettings.addLocation'),
        icon: 'fa-map-marker-plus',
        action: this.addOrEditLocation
      };
    }
  },
  methods: {
    addOrEditLocation() {
      this.$bvModal.show('add-location-modal');
    },
    addOrEditEmployee() {
      this.$bvModal.show('add-employee-modal');
    },
    addOrEditService() {
      this.$bvModal.show('add-service-modal');
    },
    getWeekdayName(weekdayNumber) {
      return moment.weekdays(true)[weekdayNumber - 1];
    }
  }
});

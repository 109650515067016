












import VendorBookingSettingsForm from '@/components/vendors/VendorBookingSettingsForm.vue';
import VendorResources from '@/components/vendors/VendorResources.vue';
import vendorsService from '@/services/vendors.service';
import moment from 'moment';
import { API_DATE_FORMAT } from '@/constants';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import VueI18n from 'vue-i18n';
import { VendorFormData } from '@/model/vendor-form-data';
import { useUiStore } from '@/store/ui-store';
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
  components: {
    VendorBookingSettingsForm,
    VendorResources
  },
  data() {
    return {
      sendingData: false
    };
  },
  computed: {
    ...mapStores(useVendorsStore, useUiStore),
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    resourcesError(): LocaleMessage | null {
      if (
        !this.vendor.resources.location.name ||
        !this.vendor.resources.employee.firstname ||
        !this.vendor.resources.service.name
      ) {
        return this.$t('validation.required');
      } else {
        return null;
      }
    }
  },
  methods: {
    async onVendorSignUpFinalStepSubmit(form) {
      if (this.resourcesError) return;
      form.validate();
      if (form.valid) {
        try {
          this.sendingData = true;
          await vendorsService.create({
            name: this.vendor.name,
            address: this.vendor.address,
            city: this.vendor.city,
            zip: this.vendor.zip,
            uid: this.vendor.uid,
            country: this.vendor.country,
            public_name: this.vendor.public_name,
            email: this.vendor.email,
            phone: this.vendor.phone,
            actual_free_time_slot_first_booking: this.vendor
              .actual_free_time_slot_first_booking,
            available_bookings_interval: this.vendor
              .available_bookings_interval,
            booking_slots_proposition_interval: this.vendor
              .booking_slots_proposition_interval,
            client_exit_duration_in_minutes: this.vendor
              .client_exit_duration_in_minutes,
            cancellation_notice: this.vendor.cancellation_notice,
            pictureFile: this.vendor.pictureFile,
            description: this.vendor.description,
            employees: [
              {
                firstname: this.vendor.resources.employee.firstname,
                lastname: this.vendor.resources.employee.lastname,
                user: {
                  firstname: this.vendor.resources.employee.firstname,
                  lastname: this.vendor.resources.employee.lastname,
                  email: this.vendor.resources.employee.email,
                  password: this.vendor.resources.employee.password
                },
                timetables: [
                  {
                    valid_from: moment().format(API_DATE_FORMAT),
                    weekdays: this.vendor.resources.employee.weekdays
                  }
                ]
              }
            ],
            services: [
              {
                ...this.vendor.resources.service,
                no: 1,
                performed_order: 1,
                category_old: ''
              }
            ],
            locations: [
              {
                ...this.vendor.resources.location
              }
            ]
          });
          await this.$router.push({ name: 'Login' });
        } catch (exception) {
          this.uiStore.alert(exception.message);
        }
        this.sendingData = false;
      }
    }
  }
});

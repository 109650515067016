






















import Vue from 'vue';

export default Vue.extend({
  name: 'StepsPill',
  props: {
    number: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    },
    isDone: {
      type: Boolean,
      required: true
    }
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(form){return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-12",class:_vm.vendorSignUpFinalStep ? 'h5 pt-3 mb-4' : 'h6 font-weight-bold'},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.title'))+" ")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert-light mb-4 rounded p-3"},[_c('div',{staticClass:"small text-dark"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.bookingSettingsInformation'))+" ")])])]),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.client_exit_duration_in_minutes,"errors":_vm.errors,"rules":"required|min:0|divider:5","type":"number","name":"client_exit_duration_in_minutes","label":_vm.$t('label.clientExitDuration'),"help-text":_vm.$t('helpText.clientExitDuration'),"append-text":_vm.$t('label.append.minutes')},on:{"onValueChange":function (value) {
            _vm.vendor.client_exit_duration_in_minutes = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.booking_slots_proposition_interval,"errors":_vm.errors,"rules":"required|min:5|max:60|divider:5","type":"number","name":"booking_slots_proposition_interval","label":_vm.$t('label.bookingSlotsPropositionInterval'),"help-text":_vm.$t('helpText.bookingSlotsPropositionInterval'),"append-text":_vm.$t('label.append.minutes')},on:{"onValueChange":function (value) {
            _vm.vendor.booking_slots_proposition_interval = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.available_bookings_interval,"errors":_vm.errors,"rules":"required|min:0|divider:5","type":"number","name":"available_bookings_interval","label":_vm.$t('label.availableBookingsInterval'),"help-text":_vm.$t('helpText.availableBookingsInterval'),"append-text":_vm.$t('label.append.minutes')},on:{"onValueChange":function (value) {
            _vm.vendor.available_bookings_interval = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.actual_free_time_slot_first_booking,"errors":_vm.errors,"rules":"required|min:0|divider:5","type":"number","name":"actual_free_time_slot_first_booking","label":_vm.$t('label.actualFreeTimeSlotFirstBooking'),"help-text":_vm.$t('helpText.actualFreeTimeSlotFirstBooking'),"append-text":_vm.$t('label.append.minutes')},on:{"onValueChange":function (value) {
            _vm.vendor.actual_free_time_slot_first_booking = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.cancellation_notice,"errors":_vm.errors,"rules":"required|min:0","type":"number","name":"cancellation_notice","label":_vm.$t('label.cancellationNotice'),"help-text":_vm.$t('helpText.cancellationNotice'),"append-text":_vm.$t('label.append.hours')},on:{"onValueChange":function (value) {
            _vm.vendor.cancellation_notice = value;
          }}}),_c('select-input',{staticClass:"col-md-6",attrs:{"name":"email_default_language","options":_vm.languageOptions,"allow-empty":false,"rules":"required","label":_vm.$t('label.emailDefaultLanguage'),"value":_vm.vendor.email_default_language},on:{"onValueChange":function (value) {
            _vm.vendor.email_default_language = value;
          }}})],1),(!_vm.vendorSignUpFinalStep)?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.reminderTitle'))+" ")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert-light mb-4 rounded p-3"},[_c('div',{staticClass:"small text-dark"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.generalSettings.remindersInformation'))+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('switch-toggle',{staticClass:"mr-2 mb-3",attrs:{"checked":_vm.vendor.sms_reminder,"value":_vm.vendor.sms_reminder},on:{"onValueChange":function (value) {
              _vm.vendor.sms_reminder = value;
            }}}),(!_vm.vendor.sms_reminder)?_c('span',{staticClass:"tw-text-sm tw-text-gray-600"},[_vm._v(_vm._s(_vm.$t('label.smsReminderSwitchOff')))]):_vm._e(),(_vm.vendor.sms_reminder)?_c('span',{staticClass:"tw-text-sm tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('label.smsReminderSwitchOn')))]):_vm._e(),(_vm.vendor.sms_reminder && _vm.smsCount > 0)?_c('alert',{attrs:{"variant":_vm.AlertType.INFO,"message":_vm.$tc('vendorSignUp.generalSettings.remainingCredits', _vm.smsCount)}}):_vm._e(),(_vm.vendor.sms_reminder && _vm.smsCount === 0)?_c('alert',{attrs:{"variant":_vm.AlertType.WARNING,"message":_vm.$t('vendorSignUp.generalSettings.remainingCreditsZero')}}):_vm._e(),(_vm.vendor.sms_reminder)?_c('text-input',{attrs:{"value":_vm.vendor.sms_reminder_offset,"inline":true,"errors":_vm.errors,"rules":_vm.vendor.sms_reminder ? 'required|min:0' : '',"type":"number","name":"sms_reminder_offset","label":_vm.$t('label.smsReminderOffset'),"help-text":_vm.$t('helpText.smsReminderOffset'),"append-text":_vm.$t('label.append.hours')},on:{"onValueChange":function (value) {
              _vm.vendor.sms_reminder_offset = value;
            }}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('switch-toggle',{staticClass:"mr-2 mb-3",attrs:{"checked":_vm.vendor.email_reminder,"value":_vm.vendor.email_reminder},on:{"onValueChange":function (value) {
              _vm.vendor.email_reminder = value;
            }}}),(!_vm.vendor.email_reminder)?_c('span',{staticClass:"tw-text-sm tw-text-gray-600"},[_vm._v(_vm._s(_vm.$t('label.emailReminderSwitchOff')))]):_vm._e(),(_vm.vendor.email_reminder)?_c('span',{staticClass:"tw-text-sm tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('label.emailReminderSwitchOn')))]):_vm._e(),(_vm.vendor.email_reminder)?_c('text-input',{attrs:{"value":_vm.vendor.email_reminder_offset,"inline":true,"errors":_vm.errors,"rules":_vm.vendor.email_reminder ? 'required|min:0' : '',"type":"number","name":"sms_reminder_offset","label":_vm.$t('label.emailReminderOffset'),"help-text":_vm.$t('helpText.emailReminderOffset'),"append-text":_vm.$t('label.append.hours')},on:{"onValueChange":function (value) {
              _vm.vendor.email_reminder_offset = value;
            }}}):_vm._e()],1)]):_vm._e(),(!_vm.vendorSignUpFinalStep)?_c('div',{staticClass:"row"},[_c('submit-button',{staticClass:"col-12",attrs:{"loading":_vm.sendingData,"label":"Enregistrer"},on:{"onClick":function () { return _vm.onSubmit(form); }}})],1):_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-link pl-0",on:{"click":function($event){return _vm.$emit('previous')}}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('button.previous'))+" ")])]),_c('button',{staticClass:"col-6 btn w-100 btn-primary",on:{"click":function($event){return _vm.$emit('submitVendorSignupForm', form)}}},[_vm._v(" "+_vm._s(_vm.$t('button.complete'))+" ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
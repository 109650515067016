




















































































import TextInput from '@/components/shared/forms/TextInput.vue';
import PasswordInput from '@/components/shared/forms/PasswordInput.vue';
import { ValidationObserver } from 'vee-validate';
import TimetableDayForm from '@/components/timetables/TimetableDayForm.vue';
import Vue, { PropType } from 'vue';
import { Employee } from '@/model/employee';

export default Vue.extend({
  components: {
    TimetableDayForm,
    PasswordInput,
    TextInput,
    ValidationObserver
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true
    }
  },
  data: () => {
    return {
      editingEmployee: {} as Partial<Employee>
    };
  },
  methods: {
    submit(modalRef, form) {
      form.validate();
      if (form.invalid) {
        modalRef.preventDefault();
        return;
      }
      this.$emit('addEmployee', this.editingEmployee);
    }
  }
});
















































































import TextInput from '@/components/shared/forms/TextInput.vue';
import { ValidationObserver } from 'vee-validate';
import Vue, { PropType } from 'vue';
import { Location } from '@/model/location';

export default Vue.extend({
  components: { TextInput, ValidationObserver },
  props: {
    location: {
      type: Object as PropType<Location>,
      required: true
    }
  },
  data: () => {
    return {
      editingLocation: {} as Location
    };
  },
  methods: {
    submit(modalRef, form) {
      form.validate();
      if (form.invalid) {
        modalRef.preventDefault();
        return;
      }
      this.$emit('addLocation', this.editingLocation);
    },
    locationNameUpdate(name: string, form) {
      if (form.fields.short_name && !form.fields.short_name.dirty) {
        this.editingLocation.short_name = name.substring(0, 2);
      }
      this.editingLocation.name = name;
    }
  }
});



























































































































































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { VendorSettingsPage } from '@/model/vendor-settings-page';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { VendorFormData } from '@/model/vendor-form-data';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { getAvailableLanguagesOptions } from '@/config/i18n';
import SwitchToggle from '@/components/shared/SwitchToggle.vue';
import Alert from '@/components/shared/Alert.vue';
import { AlertType } from '@/store/ui-store';

export default Vue.extend({
  name: 'VendorForm',
  components: {
    SubmitButton,
    TextInput,
    SelectInput,
    SwitchToggle,
    Alert
  },
  props: {
    vendorSignUpFinalStep: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      key: 0,
      languageOptions: [],
      AlertType: AlertType
    };
  },
  computed: {
    ...mapStores(useVendorsStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    smsCount(): number {
      return this.vendorsStore.smsCount;
    },
    errors(): {} {
      return this.vendorsStore.validationErrors;
    }
  },
  created() {
    this.getLanguageOptions();
    if (this.vendorSignUpFinalStep) {
      this.vendor.email_default_language = this.$i18n.locale;
    }
  },
  methods: {
    getLanguageOptions() {
      const languages = getAvailableLanguagesOptions();
      languages.forEach(language => {
        this.languageOptions.push({
          label: language.name,
          value: language.value
        });
      });
    },
    async onSubmit(form) {
      form.validate();
      if (form.valid) {
        await this.vendorsStore.edit(this.vendor, VendorSettingsPage.BOOKING);
      }
    }
  }
});

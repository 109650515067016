













































































import TextInput from '@/components/shared/forms/TextInput.vue';
import TextareaInput from '@/components/shared/forms/TextareaInput.vue';
import { ValidationObserver } from 'vee-validate';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCategoriesStore } from '@/store/categories-store';

export default Vue.extend({
  name: 'AddServiceModal',
  components: {
    SelectInput,
    TextareaInput,
    TextInput,
    ValidationObserver
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      editingService: {}
    };
  },
  computed: {
    ...mapStores(useCategoriesStore),
    categoriesOptions(): {} {
      return this.categoriesStore.entities.map(category => ({
        value: category.id,
        label: category.name
      }));
    }
  },
  methods: {
    submit(modalRef, form) {
      form.validate();
      if (form.invalid) {
        modalRef.preventDefault();
        return;
      }
      this.$emit('addService', this.editingService);
    }
  }
});
















import Vue from 'vue';

export default Vue.extend({
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onValueChange', value);
    }
  }
});











































import StepsPill from '@/components/shared/StepsPill.vue';
import VendorLegalInformationForm from '@/components/vendors/VendorLegalInformationForm.vue';
import VendorPublicInformationForm from '@/components/vendors/VendorPublicInformationForm.vue';
import VendorResourcesForm from '@/components/vendors/VendorResourcesForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCountriesStore } from '@/store/countries-store';
import { useCategoriesStore } from '@/store/categories-store';
import { useVendorsStore } from '@/store/vendors-store';

export default Vue.extend({
  name: 'VendorSignUpPage',
  components: {
    VendorLegalInformationForm,
    VendorPublicInformationForm,
    VendorResourcesForm,
    StepsPill
  },
  data: () => {
    return {
      step: 1
    };
  },
  computed: {
    ...mapStores(useCountriesStore, useCategoriesStore, useVendorsStore)
  },
  created() {
    this.countriesStore.findAll();
    this.categoriesStore.findAll(null);
    this.vendorsStore.getNewVendor();
  },
  methods: {
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    }
  }
});
